export default async (errorMessage) => {
  const urlParts = window.location.pathname.split("/").filter(Boolean);
  const uuid = urlParts[urlParts.length - 1];
  const userAgent = navigator.userAgent;
  const screenResolution = `${window.screen.width}x${window.screen.height}`;
  const timestamp = new Date().toISOString();
  fetch("/next_of_kin/report_error", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": document.querySelector("meta[name='csrf-token']")?.content,
    },
    body: JSON.stringify({
      error_message: `
        JS Error: ${errorMessage},
        Timestamp: ${timestamp},
        UUID: ${uuid},
        User Agent: ${userAgent},
        Screen Resolution: ${screenResolution}
        `,
    }),
  });
};
